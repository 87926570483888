
          @import "./src/styles/_index.scss";
        
.root {
  display: flex;
  align-items: center;

  span {
    margin-left: 1rem;
    line-height: 1;
  }

  b {
    font-weight: $fontWeightBold;
  }
}
