
          @import "./src/styles/_index.scss";
        

.root {
  @include contentWrapper;
}

.ctaContainer {
  @include flexCenter;
  margin: spacing(10);
}

