
          @import "./src/styles/_index.scss";
        
.root {
  padding: spacing(0, 6);
  background-color: #ffffff;
  width: 100%;
  border: 0.1rem solid $colorGrayMedium;
  border-radius: $radius8;
  display: table;
}
