
          @import "./src/styles/_index.scss";
        
.root {
  display: inline-flex;
  min-width: 0;
  max-width: 100%;
  white-space: nowrap;

  > span {
    white-space: nowrap;
  }
}

.middle {
  overflow: hidden;
  text-overflow: ellipsis;
}
