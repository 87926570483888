
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
}

.topMost {
  z-index: 1999;
  position: relative;
}

.swap:disabled {
  opacity: 0.5;
}

.balance {
  position: absolute;
  right: 0;
  transform: translateY(-#{spacing(3)});
}
