$datepicker__background-color: $colorGrayCool;
$datepicker__border-color: $colorGray--Light;
$datepicker__highlighted-color: $colorOrange;
$datepicker__holidays-color: $colorViolet;
$datepicker__muted-color: $colorGrayMedium;
$datepicker__selected-color: $colorBlue--Light;
$datepicker__text-color: $colorGray--Darkest;
$datepicker__header-color: $colorGray--Darkest;
$datepicker__navigation-disabled-color: $colorGrayWarm;

$datepicker__border-radius: $defaultRadius;
$datepicker__day-margin: 0.2rem;
$datepicker__font-size: 1.2rem;
$datepicker__font-family: 'Graphik', helvetica, arial, sans-serif;
$datepicker__item-size: 2.6rem;
$datepicker__margin: 0.4rem;
$datepicker__navigation-button-size: 2.8rem;
$datepicker__triangle-size: 8px;
