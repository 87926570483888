
          @import "./src/styles/_index.scss";
        
.root {
  color: $colorNavyDark;
  font-size: 1.8rem;
  font-weight: $fontWeightBold;
  margin-bottom: spacing(1);
  white-space: nowrap;
}

.arrowIcon {
  margin: 0 spacing(2);
}
