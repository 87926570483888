@mixin timing-function {
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes #{$rt-namespace}__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  from {
    opacity: 0;
    transform: translate3d(300rem, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-2.5rem, 0, 0);
  }
  75% {
    transform: translate3d(1rem, 0, 0);
  }
  90% {
    transform: translate3d(-0.5rem, 0, 0);
  }
  to {
    transform: none;
  }
}

@keyframes #{$rt-namespace}__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-2rem, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(200rem, 0, 0);
  }
}

@keyframes #{$rt-namespace}__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  0% {
    opacity: 0;
    transform: translate3d(-300rem, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(2.5rem, 0, 0);
  }
  75% {
    transform: translate3d(-1rem, 0, 0);
  }
  90% {
    transform: translate3d(0.5rem, 0, 0);
  }
  to {
    transform: none;
  }
}

@keyframes #{$rt-namespace}__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(2rem, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-200rem, 0, 0);
  }
}

@keyframes #{$rt-namespace}__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  from {
    opacity: 0;
    transform: translate3d(0, 300rem, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -2rem, 0);
  }
  75% {
    transform: translate3d(0, 1rem, 0);
  }
  90% {
    transform: translate3d(0, -0.5rem, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes #{$rt-namespace}__bounceOutUp {
  20% {
    transform: translate3d(0, -1rem, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 2rem, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -200rem, 0);
  }
}

@keyframes #{$rt-namespace}__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -300rem, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 2.5rem, 0);
  }
  75% {
    transform: translate3d(0, -1rem, 0);
  }
  90% {
    transform: translate3d(0, 0.5rem, 0);
  }
  to {
    transform: none;
  }
}

@keyframes #{$rt-namespace}__bounceOutDown {
  20% {
    transform: translate3d(0, 1rem, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -2rem, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 200rem, 0);
  }
}

.#{$rt-namespace}__bounce-enter {
  &--top-left,
  &--bottom-left {
    animation-name: #{$rt-namespace}__bounceInLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$rt-namespace}__bounceInRight;
  }
  &--top-center {
    animation-name: #{$rt-namespace}__bounceInDown;
  }
  &--bottom-center {
    animation-name: #{$rt-namespace}__bounceInUp;
  }
}

.#{$rt-namespace}__bounce-exit {
  &--top-left,
  &--bottom-left {
    animation-name: #{$rt-namespace}__bounceOutLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$rt-namespace}__bounceOutRight;
  }
  &--top-center {
    animation-name: #{$rt-namespace}__bounceOutUp;
  }
  &--bottom-center {
    animation-name: #{$rt-namespace}__bounceOutDown;
  }
}
