
          @import "./src/styles/_index.scss";
        
.root {
  @include typoBodyCopySmall;
  border-radius: $radiusInfinite;
  padding: spacing(1, 3);
  display: inline-flex;
  align-items: center;
}

.color-- {
  &green {
    color: $colorGreenLight;
    background-color: $colorGreen--Lightest;
  }
  &red {
    color: $colorRed--Base;
    background-color: $colorRed--Lightest;
  }
  &yellow {
    color: $colorYellow--Dark;
    background-color: $colorYellowLight;
  }
  &orange {
    color: $colorWhite;
    background-color: $colorOrange;
  }
  &grayLight {
    background-color: $colorGray--Light;
  }
  &grayLighter {
    background-color: $colorGrayMedium;
  }
  &grayLightest {
    background-color: $colorGrayCool;
  }
}
