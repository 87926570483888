
          @import "./src/styles/_index.scss";
        
.root {
  width: 48rem;
  min-width: 32rem;
  height: 5.4rem;
  margin: spacing(6) 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: spacing(3) spacing(4);
  color: $colorGray--Darkest;
  background-color: $colorWhite;
  line-height: 1.8;
  @include typoBodyCopyStandard;
  border: 0.1rem solid $colorCharcoal;
  border-radius: $defaultRadius;
  overflow: hidden;
}

.fileIcon {
  flex-shrink: 0;
  margin-right: spacing(2);
}

.fileName {
  flex-grow: 1;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: spacing(2);
}

.deleteButton {
  flex-shrink: 0;
  margin-left: auto;
  padding-left: spacing(2);
}
