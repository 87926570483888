
          @import "./src/styles/_index.scss";
        
.root {
  @include flexCenter;
  height: 4.4rem;
  width: 17rem;
  background-color: $colorWhite;
  border: 0.1rem solid $colorGrayMedium;
  border-radius: 999rem;
  font-weight: $fontWeightBold;
  box-shadow: spacing(0, 1, 1, 0) rgba($colorBlackWarm, 0.1);
  cursor: pointer;
  left: calc(50% - 17rem / 2);

  &:hover {
    background-color: $colorGrayCoolLighter;
  }

  &--enter {
    opacity: 0;
    transform: translateY(1rem);
  }
  &--enterActive {
    opacity: 1;
    transform: translateY(0);
    transition: $animationDefaultTransition;
  }
  &--exit {
    opacity: 1;
    transform: translateY(0);
  }
  &--exitActive {
    transition: $animationDefaultTransition;
    opacity: 0;
    transform: translateY(1rem);
  }
}

.icon {
  margin-right: spacing(2);
}
