
          @import "./src/styles/_index.scss";
        
.closeButtonIcon {
  margin-top: 0.3rem;
  margin-left: spacing(3);
  color: $colorGray--Darker;
  @include defaultTransition;

  &:hover {
    color: $colorGray--Darkest;
  }
}
