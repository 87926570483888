
          @import "./src/styles/_index.scss";
        
.wrap {
  overflow-wrap: anywhere;
}

.qrCode {
  margin: auto;
  display: block;
  width: 24rem;
  max-width: 80%;
}
