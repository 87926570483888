$rt-namespace: 'Toastify';
$rt-mobile: 'only screen and (max-width : 480px)' !default;

:root {
  --toastify-color-light: #{$colorWhite};
  --toastify-color-dark: #{$colorGray--Darkest};
  --toastify-color-info: #{$colorViolet};
  --toastify-color-success: #{$colorGreenLight};
  --toastify-color-warning: #{$colorYellow--Base};
  --toastify-color-error: #{$colorRed--Base};
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 48rem;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 4.6rem;
  --toastify-toast-max-height: 80rem;
  --toastify-font-family: #{$fontMainFamily};
  --toastify-z-index: #{$zindexNotifications};

  --toastify-text-color-light: #{$colorGray--Darkest};
  --toastify-text-color-dark: #{$colorWhite};

  //Used only for colored theme
  --toastify-text-color-info: #{$colorWhite};
  --toastify-text-color-success: #{$colorWhite};
  --toastify-text-color-warning: #{$colorWhite};
  --toastify-text-color-error: #{$colorWhite};

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: #{$colorOrange};
  --toastify-color-progress-dark: #{$colorOrange};
  --toastify-color-progress-info: #{$colorJetStream--Dark};
  --toastify-color-progress-success: #{$colorGreen--Lighter};
  --toastify-color-progress-warning: #{$colorYellow--Light};
  --toastify-color-progress-error: #{$colorRed--Lighter};
}
