
          @import "./src/styles/_index.scss";
        
.root {
  display: flex;
  padding-bottom: spacing(3);
  margin-bottom: spacing(6);
  border-bottom: 0.1rem solid $colorGrayCool;
  gap: spacing(3);

  > div:first-child {
    margin-right: spacing(10);
  }
}

.stablecoin {
  background: $colorGrayCool;
  padding: spacing(4);
  border-radius: $defaultRadius;
  gap: spacing(1);

  .tokenAndChain {
    display: inline-block;
    position: relative;
    width: spacing(7.5);
    height: spacing(7);
  }

  .networkIcon {
    position: absolute;
    top: spacing(3);
    left: spacing(3);
  }
}

.actions {
  display: flex;

  .tooltip,
  > button {
    display: inline;
    margin: spacing(1) spacing(2) 0 0;
  }
}
