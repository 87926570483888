.#{$rt-namespace}__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: spacing(3, 6);
  border-radius: $defaultRadius;
  border: 0.1rem solid $colorGray--Light;
  display: inline-flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  &--rtl {
    direction: rtl;
  }
  &--close-on-click {
    cursor: pointer;
  }
  &-body {
    @include typoBodyCopySmall;
    margin: auto 0;
    flex: 1 1 auto;
    padding: 0;
    display: flex;
    align-items: center;
    text-align: left;
    & > div:last-child {
      word-break: break-word;
      flex: 1;
    }
  }
  &-icon {
    margin-inline-end: spacing(3);
    width: spacing(5);
    flex-shrink: 0;
    display: flex;
  }
}

.#{$rt-namespace}--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.#{$rt-namespace}--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast {
    margin-bottom: 0;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    display: flex;
  }
}
