
          @import "./src/styles/_index.scss";
        
.root {
  margin-top: spacing(4);
  gap: spacing(1);
  display: flex;

  & > * {
    flex: 1;
  }
}