
          @import "./src/styles/_index.scss";
        
.root {
  @include defaultTransition(border-bottom);
  cursor: pointer;
  background-color: transparent;
  border: none;
  @include flexCenter;
}

.counter {
  @include flexCenter;
  display: inline-flex;
  margin-left: spacing(2.5);
  height: 2.4rem;
  min-width: 2.4rem;
  padding: spacing(0, 1);
  border-radius: 999rem;
  background-color: $colorViolet;
  color: $colorWhite;
  font-size: 1.2rem;
  font-weight: $fontWeightBold;
  text-shadow: none !important;

  &.noItems {
    background-color: $colorGrayCool;
    color: $colorCharcoal;
    font-weight: $fontWeightNormal;
  }
}

.fauxLabel {
  display: none;
}

.variant-- {
  &primary,
  &secondary {
    min-height: 5rem;
    padding: spacing(3, 6, 2, 6);
    border-bottom: spacing(1) solid transparent;
    line-height: 1;
    color: $colorNavyDark;

    // Instead of font weight, we use text shadow to make the text looks bold.
    // It prevents content from shifting when the text is bolded.
    &:not(.isActive):hover {
      font-weight: $fontWeightBold;
    }

    &.isActive {
      border-bottom-color: $colorMediumViolet;
      font-weight: $fontWeightBold;
    }

    .fauxLabel {
      display: block;
      visibility: hidden;
      font-weight: $fontWeightBold !important;
    }

    .labelWrapper {
      position: relative;
    }

    .label {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  &primary {
    &:hover,
    &.isActive {
      color: $colorMediumViolet;
    }
  }

  &secondary {
    border-bottom-width: spacing(0.5);
    &.isActive {
      border-bottom-color: $colorNavyDark;
    }
  }

  &picker {
    border-radius: $defaultRadius;
    @include typoBodyCopySmall;
    font-weight: $fontWeightBold;
    flex: 1;
    padding: spacing(1.5);

    &:hover {
      border-bottom-color: transparent;
    }

    &.isActive {
      color: $colorGray--Darkest;
      background-color: $colorWhite;
      border: 0.1rem solid $colorGrayMedium;
    }

    .counter {
      height: 2rem;
      min-width: 2rem;
      font-size: 1.1rem;
    }
  }
}
