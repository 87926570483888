@use 'sass:list';

/* UI defaults */

$baseFontSize: 10px;

$animationDefaultTime: 400ms;
$animationDefaultTransition: all $animationDefaultTime ease-in-out;
$defaultRadius: 0.6rem;
$radius8: 0.8rem;
$radius16: 1.6rem;
$radiusInfinite: 9999rem;

$zindexNavbar: 1000;
$zindexTopbar: 2000;
$zindexFormOverlayElement: 3000;
$zindexDropdownMenu: 4000;
$zindexBackdrop: 5000;
$zindexNotifications: 8000;
$zindexMobileMenu: 9000;

/* Spacing for margins and paddings */

$spacingBase: 0.4rem;

@function spacing($numbers...) {
  $parsedList: ();
  @each $number in $numbers {
    $parsedList: list.append($parsedList, $number * $spacingBase);
  }
  @return $parsedList;
}

/* Helpers */

@mixin container {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
}

@mixin defaultTransition($property: all) {
  transition: $property $animationDefaultTime ease-in-out;
}

@mixin flexBasisGutter($elementsCount, $gutterSize) {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(
    #{calc(100 / $elementsCount)}% - #{calc($gutterSize * ($elementsCount - 1) / $elementsCount)}
  );
}

@mixin flexCenter($direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: center;
  justify-content: center;
}

/* Shadows */

@mixin shadowModalBox {
  box-shadow: 0rem 0.4rem 0.2rem rgba($colorBlackWarm, 0.05);
}

/* Core element styles */

@mixin contentWrapper {
  padding: spacing(8, 10);

  @media #{$tablet-down} {
    padding: spacing(5, 4);
  }
}
