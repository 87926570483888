
          @import "./src/styles/_index.scss";
        
.root {
  @include contentWrapper;
}

.header {
  margin-top: spacing(12);
  margin-bottom: spacing(2);
}

.horizontalButtons {
  display: flex;
  flex-direction: row;
  button {
    margin: spacing(2);
  }
}


.inviteMembersEntries {
  @include flexCenter(column);
  width: 100%;
}

.addNewMember {
  margin-bottom: spacing(3);
}

.interactiveIcon {
  cursor: pointer;
}

.form {
  margin-left: auto;
  margin-right: auto;
  @include flexCenter(column);
  max-width: 57.6rem;
  margin-top: spacing(6);
}

.input {
  margin: 0;
}

.itemRow {
  width: 100%;
  display: flex;
  margin-bottom: spacing(6);
}

.itemRowIcon {
  margin-left: spacing(4);
  margin-top: spacing(4);
}
