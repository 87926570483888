
          @import "./src/styles/_index.scss";
        
.root {
  @include flexCenter(column);
  @include contentWrapper;
  min-height: 100vh;
}

.label {
  margin-top: spacing(8);
}
