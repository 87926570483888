@import './variables.scss';
@import './vendor-original';

%navigation-chevron {
  border-color: $datepicker__muted-color;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;

  &--disabled,
  &--disabled:hover {
    border-color: $datepicker__navigation-disabled-color;
    cursor: default;
  }
}

.react-datepicker {
  z-index: $zindexFormOverlayElement;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  &[data-placement^='bottom'] {
    .react-datepicker__triangle {
      margin-bottom: -0.1rem;
      z-index: 999;
    }
  }

  &[data-placement^='top'] {
    .react-datepicker__triangle {
      margin-top: -0.1rem;
      z-index: 999;
    }
  }
}
