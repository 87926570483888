
          @import "./src/styles/_index.scss";
        
.root {
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  background-color: $colorGrayCool;
  border-radius: 50%;
  @include defaultTransition;
}
