
          @import "./src/styles/_index.scss";
        
.root {
  width: 100%;
  display: table-row;
}

.bulletCell {
  display: table-cell;
  white-space: nowrap;
  padding: spacing(4.3, 0);
  color: $colorDarkestViolet;
}

.valueCell {
  display: table-cell;
  padding: spacing(4, 6, 4, 4);
  vertical-align: top;
  width: 10000px;
}
