
          @import "./src/styles/_index.scss";
        
.root {
  padding-bottom: spacing(4);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.headerItems {
  display: flex;

  > * {
    margin-left: spacing(5);
  }
}
