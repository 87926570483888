
          @import "./src/styles/_index.scss";
        
.root {
  border-radius: $radius8;
  color: $colorBlackWarm;
  display: inline-flex;
  align-items: center;
  font-weight: $fontWeightBold;
  font-size: 1.6rem;
}

.dot {
  margin-right: spacing(2.5);
}

.size-- {
  &small {
    padding: spacing(2, 2.5);
    @include typoLabelExtraSmall;
  }

  &normal {
    padding: spacing(2, 4);
  }
}

:not(.variant--transparent).color-- {
  &orange {
    background-color: $colorOrangeLight;
  }
  &violet {
    background-color: $colorLightestLavender;
  }
  &yellow {
    background-color: $colorYellowLight;
  }
  &blue {
    background-color: $colorBlueLight;
  }
  &gray {
    background-color: $colorGrayMedium;
  }
  &red {
    background-color: $colorRedLight;
    color: $colorRed;
  }
  &green {
    background-color: $colorGreenLightest;
    color: $colorGreen;
  }
}
