
          @import "./src/styles/_index.scss";
        
.container {
  margin: spacing(6) 0;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 44rem;
  min-height: 17.6rem;
  border: 0.1rem dashed $colorCharcoal;
  border-radius: $defaultRadius;
  padding: spacing(5) spacing(6);
  transition: $animationDefaultTransition;

  &:not(.isDisabled):hover {
    cursor: pointer;
    border-color: $colorBlackWarm;
  }

  &.hasError {
    border-color: $colorRed--Base;
  }

  &.isDisabled {
    border-color: $colorGrayMedium;
    pointer-events: none;

    * {
      color: $colorGrayMedium;
    }
  }
}

.icon {
  margin-bottom: spacing(2);
}

.title {
  @include typoBodyCopyStandard;
  color: $colorBlackWarm;
}

.fileRequirements,
.description {
  color: $colorGray--Darkest;
  @include typoBodyCopySmall;
  max-width: 48rem;
  margin-top: spacing(2);
}

.description {
  margin-top: spacing(4);
}

.highlighted {
  color: $colorOrange;
}

.errorMessage {
  max-width: 100%;
  display: block;
  margin: spacing(2) spacing(4) spacing(4);
}
