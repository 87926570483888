
          @import "./src/styles/_index.scss";
        
.default {
  &--enter {
    opacity: 0;
    transform: translateY(5rem);
    pointer-events: none;
  }
  &--enterActive {
    opacity: 1;
    transform: translateY(0);
    pointer-events: none;
    transition: $animationDefaultTransition;
  }
  &--exit {
    opacity: 1;
    transform: translateY(0);
    pointer-events: none;
  }
  &--exitActive {
    opacity: 0;
    transform: translateY(5rem);
    pointer-events: none;
    transition: $animationDefaultTransition;
  }
}

.slideout {
  &--enter {
    transform: translateX(100%);
    pointer-events: none;
  }
  &--enterActive {
    transform: translateX(0);
    pointer-events: none;
    transition: $animationDefaultTransition;
  }
  &--exit {
    transform: translateX(0);
    pointer-events: none;
  }
  &--exitActive {
    transform: translateX(100%);
    pointer-events: none;
    transition: $animationDefaultTransition;
  }
}
