
          @import "./src/styles/_index.scss";
        
.property {
  margin-top: spacing(4);

  > div {
    margin-bottom: spacing(2);
  }
}

.row {
  display: flex;
  align-items: center;

  @media #{$tablet-up} {
    max-width: 80%;
  }
}

.secretValue {
  margin-right: calc(#{spacing(2)} + 0.1em);
}
