
          @import "./src/styles/_index.scss";
        
.root {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  width: 100%;
  flex: 0 0 100%;
  border: 0;
  border-top: 0.1rem solid $colorGrayCool;
}
