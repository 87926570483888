@font-face {
  font-family: 'Graphik';
  src: url('/assets/fonts/Graphik-Regular.ttf') format('truetype');
  src: url('/assets/fonts/graphik-regular-web.eot'), url('/assets/fonts/graphik-regular-web.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/graphik-regular-web.woff2') format('woff2'), url('/assets/fonts/graphik-regular-web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/assets/fonts/Graphik-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('/assets/fonts/Graphik-Medium.ttf') format('truetype');
    src: url('/assets/fonts/graphik-medium-web.eot'), url('/assets/fonts/graphik-medium-web.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/graphik-medium-web.woff2') format('woff2'), url('/assets/fonts/graphik-medium-web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap');
