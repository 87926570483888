
          @import "./src/styles/_index.scss";
        
.root {
  @include contentWrapper;
}

.header {
  margin-top: spacing(12);
  margin-bottom: spacing(2);
}
