
          @import "./src/styles/_index.scss";
        
.list {
  border: 0;
  margin: spacing(4, 0);
}

.footer {
  width: 100%;
  bottom: 0;

  button {
    margin: 0 auto;
  }

  .buttons {
    display: flex;
    gap: 1rem;

    button {
      width: 100%;
    }
  }

  .note {
    margin: spacing(8, 0, 4, 0);

    :first-child {
     display: flex;
     gap: 0.5rem;

     div {
      flex-basis: auto;
      margin: 0;
     }
    }
  }
}
