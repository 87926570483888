
          @import "./src/styles/_index.scss";
        
.floatRight {
  position: absolute;
  right: 1rem;
  top: 0.9rem;
}

.dropdownGroup {
  margin-left: spacing(4);
}

.circle {
  border-radius: 50%;
  border: 0;
}

.menuItemCircle {
  span {
    svg {
      @extend .circle;
    }
  }
}
