$fontWeightNormal: 400;
$fontWeightBold: 500;

$fontMain: 'Graphik';
$fontMainFamily: $fontMain, Helvetica, Arial, sans-serif;

// LEGACY TYPOGRAPHY
// ----------------------------------

@mixin fontMainFamily {
  font-family: $fontMainFamily;
}

@mixin fontMonospacedFamily {
  font-family: 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@mixin typoTitle {
  @include fontMainFamily;
  color: $colorGray--Darkest;
  font-size: 2rem;
  font-weight: $fontWeightBold;
  letter-spacing: -1%;
}

@mixin typoBodyEmphasized {
  @include typoBodyCopyStandard;
  font-weight: $fontWeightBold;
  color: $colorGray--Darkest;
}

@mixin typoBodySmallEmphasized {
  @include typoBodyCopySmall;
  font-weight: $fontWeightBold;
  color: $colorGray--Darkest;
}

@mixin typoBodyEmphasized {
  @include typoBodyCopyStandard;
  font-weight: $fontWeightBold;
  color: $colorGray--Darkest;
}

@mixin typoWalletAddress {
  @include fontMonospacedFamily;
  color: $colorGray--Darkest;
  font-size: 1.6rem;
  font-weight: $fontWeightNormal;
  line-height: 2.1rem;
  letter-spacing: 0.03rem;
}

@mixin typoWalletAddressSmall {
  @include typoWalletAddress;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

@mixin typoTableHeader {
  @include fontMainFamily;
  color: $colorGray--Darkest;
  font-size: 1.3rem;
  font-weight: $fontWeightBold;
  line-height: 1.4rem;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}

// TYPOGRAPHY
// ----------------------------------

@mixin commonTypographyProperties {
  @include fontMainFamily;
  font-weight: $fontWeightNormal;
  letter-spacing: -1%;
  color: $colorBlackWarm;
}

// Section Headers

@mixin typoSectionHeaderStandard {
  @include commonTypographyProperties;
  font-weight: $fontWeightBold;
  font-size: 1.6rem;
}

@mixin typoSectionHeaderMedium {
  @include typoSectionHeaderStandard;
  font-size: 1.8rem;
}

@mixin typoSectionHeaderLarge {
  @include typoSectionHeaderStandard;
  font-size: 2rem;
  line-height: 2.2rem; // potentially remove
}

// Body Copy

@mixin typoBodyCopyStandard {
  @include commonTypographyProperties;
  font-size: 1.6rem;
  line-height: 2rem;

  b,
  strong {
    font-weight: $fontWeightBold;
  }
}

@mixin typoBodyCopySmall {
  @include typoBodyCopyStandard;
  font-size: 1.4rem;
  line-height: 1.8rem;

  b,
  strong {
    font-weight: $fontWeightBold;
  }
}

// Body Link

@mixin typoBodyLinkStandard {
  @include typoBodyCopyStandard;
  color: $colorMediumViolet;
  cursor: pointer;
}

@mixin typoBodyLinkSmall {
  @include typoBodyCopySmall;
  color: $colorMediumViolet;
  cursor: pointer;
}

// Labels

@mixin typoLabelStandard {
  @include commonTypographyProperties;
  font-size: 1.6rem;
  font-weight: $fontWeightBold;
}

@mixin typoLabelSmall {
  @include typoLabelStandard;
  font-size: 1.4rem;
}

@mixin typoLabelSmallSubLabel {
  @include typoLabelSmall;
  color: $colorCharcoal;
}

@mixin typoLabelExtraSmall {
  @include typoLabelSmall;
  font-size: 1.2rem;
}

// Sub Copy

@mixin typoSubCopyStandard {
  @include commonTypographyProperties;
  font-size: 1.6rem;
  color: $colorCharcoal;
  line-height: 2rem;
}

@mixin typoSubCopySmall {
  @include typoSubCopyStandard;
  font-size: 1.4rem;
}

// Tabs

@mixin typoTabStandard {
  @include commonTypographyProperties;
  color: $colorNavyDark;
  font-size: 1.6rem;
}

@mixin typoTabBolded {
  @include typoTabStandard;
  font-weight: $fontWeightBold;
}

@mixin typoTabSmall {
  @include typoTabStandard;
  font-size: 1.4rem;
}

@mixin typoTabBolded {
  @include typoTabSmall;
  font-weight: $fontWeightBold;
}
