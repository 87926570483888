
          @import "./src/styles/_index.scss";
        
.root {
  width: 100%;
  margin-bottom: spacing(2.5);
  border: 0.1rem solid $colorGrayMedium;
  border-radius: $radius8;
  background-color: $colorWhite;
  padding: spacing(6);
  display: grid;
  // 16.5rem = (3rem + 3.6rem + 20rem + spacing(4 ) * 4) / 2
  grid-template-columns: calc(50% - 13.5rem) calc(50% - 20.5rem) 28rem;
  column-gap: spacing(8);
  align-items: center;
  @include defaultTransition;

  &:hover {
    cursor: pointer;
    background-color: $colorGrayCoolLighter;

    .iconWrapper {
      background-color: $colorGrayMedium;
    }
  }
}

.cellDetails {
  display: flex;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: $fontWeightBold;
  margin-right: spacing(4);

  .month {
    display: block;
    color: $colorCharcoal;
    font-size: 1.4rem;
  }

  .day {
    color: $colorNavyDark;
    font-size: 1.8rem;
  }
}

.iconWrapper {
  margin-right: spacing(4);
}

.labelAndSublabelCell {
  overflow: hidden;
}

.targetAmountAndAsset {
  color: $colorNavyDark;
  font-size: 1.8rem;
  font-weight: $fontWeightBold;
  margin-bottom: spacing(1);
}

.senderAndAmount,
.recipient {
  color: $colorCharcoal;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.senderAndAmount {
  max-width: 100%;
  display: flex;

  .sender {
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 3rem;
  }
}

.amount {
  overflow-wrap: anywhere;
}

.cellStatusBadge {
  text-align: right;
}
