
          @import "./src/styles/_index.scss";
        
.badge {
  cursor: pointer;
}

.banks {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 58rem;
}
