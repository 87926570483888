
          @import "./src/styles/_index.scss";
        
.root {
  @include contentWrapper;
}

.continueButton {
  width: 100%;
}
