
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
  margin: spacing(7) 0;

  &.isHidden {
    display: none;
  }
}

.fieldWrapper {
  position: relative;

  &:not(.isDisabled),
  &:not(.isDisabled) [class$='-control'] {
    cursor: pointer;
  }

  [class$='-control'] {
    border: 0;
    box-shadow: none;

    &[aria-disabled='true'] {
      background-color: transparent;
    }
  }

  [class$='-menu'] {
    box-shadow: none;
    border: 0.1rem solid $colorGray--Darker;
    border-radius: $defaultRadius;
    background-color: $colorWhite;
    margin-top: 0.4rem;
    padding: 0.2rem 0;
  }

  .fieldset {
    position: absolute;
    inset: -0.6rem 0 0;
    pointer-events: none;
    border: 0.1rem solid $colorCharcoal;
    border-radius: $defaultRadius;
    overflow: hidden;
    min-width: 0;

    .legend {
      @include typoBodyCopyStandard;
      display: block;
      padding: 0;
      white-space: nowrap;
      max-width: 0.001rem;
      margin-left: -0.2rem;
      font-size: 1.2rem;
      height: 1.2rem;
      visibility: hidden;
      overflow: hidden;
      transition: max-width 50ms linear 100ms;

      span {
        padding: 0 0.5rem;
        display: block;
      }
    }
  }

  [class$='-placeholder'] {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  .label {
    @include typoBodyCopyStandard;
    position: absolute;
    left: 1.7rem;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 3.2rem);
    transition: all 200ms ease-in-out;
    transition-property: top, font-size;
  }

  &:hover fieldset {
    border-width: 0.2rem;
  }

  &.isFocused {
    fieldset {
      border-width: 0.2rem;
    }

    [class$='-placeholder'] {
      opacity: 1;
    }
  }

  &.isFocused,
  &.hasValue {
    fieldset {
      .legend {
        max-width: 100%;
      }
    }

    .label {
      font-size: 1.2rem;
      top: -0.9rem;
      color: $colorGray--Darker;
    }
  }

  &.hasError {
    fieldset {
      border-color: $colorRed--Base;
      border-width: 0.2rem;
    }

    .label {
      color: $colorRed--Base;
    }
  }

  &.isDisabled {
    cursor: default;

    .label,
    fieldset {
      color: $colorGrayMedium;
      border-color: $colorGrayMedium;
      border-width: 0.1rem;
    }

    [class$='-control'] {
      opacity: 0.5;
    }
  }
}

.supportingTextWrapper {
  max-width: 100%;
  overflow: hidden;
  font-size: 1.2rem;
  color: $colorCharcoal;
  padding: 0.8rem 1.6rem 0;

  &.forDisabledField {
    color: $colorGrayMedium;
  }
}

.size-- {
  &normal {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .label {
      top: 1.4rem;
    }
  }

  &small {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-size: 1.4rem;

    .label {
      top: 1rem;
      font-size: 1.4rem;
    }

    [class$='-control'] {
      min-height: 3.4rem;
    }
  }
}

.variant--v2 {
  .fieldset {
    border-color: $colorGrayWarm;
  }
}
