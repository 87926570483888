
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
}

.dropdownContent {
  display: none;
  margin-top: 0.3rem;
  position: absolute;
  border: 0.1rem solid $colorGrayCool;
  border-radius: $defaultRadius;
  box-shadow: 0 0 2rem 0 rgba($colorBlackWarm, 0.05);
  background-color: $colorWhite;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  z-index: $zindexDropdownMenu;

  &.isOpen {
    display: block;
  }

  &.showArrow {
    margin-top: 0.8rem;

    &::before,
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: -0.8rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      z-index: $zindexDropdownMenu;
    }

    &::after {
      border-width: 0 0.65rem 0.8rem 0.65rem;
      border-bottom-color: $colorWhite;
    }

    &::before {
      border-width: 0 0.75rem 0.8rem 0.75rem;
      border-bottom-color: $colorGrayCool;
    }

    &.menuPosition--left {
      &::before {
        left: 0.8rem;
      }
      &::after {
        left: 0.9rem;
      }
    }

    &.menuPosition--right {
      &::before {
        right: 0.8rem;
      }
      &::after {
        right: 0.9rem;
      }
    }
  }

  &.menuWidth--normal {
    width: 16rem;
  }

  &.menuWidth--wide {
    width: 22rem;
  }
}
