/*
  Do not import this file to JS/TS directly.
  Instead, use _colorsExportToJS proxy and use colors.ts allowing webpack to tree shake them.
*/

// LEGACY COLORS
// ----------------------------------
$colorBlue--Base: #3c28c1;
$colorBlue--Dark: #2f1f98;
$colorBlue--Light: #4934d5;
$colorBlue--Lightest: #8678e3;
$colorGray--Dark: #67647d;
$colorGray--Darker: #42404f;
$colorGray--Darkest: #26242d;
$colorGray--Light: #a7a4b6;
$colorGreen--Light: #98c278;
$colorGreen--Lighter: #c4dcb2;
$colorGreen--Lightest: #f0f6ec;
$colorJetStream--Dark: #adc2b9;
$colorJetStream--Light: #d4e2dc;
$colorJetStream--Lighter: #e6eeeb;
$colorJetStream--Lightest: #f9fbfa;
$colorOrange--Dark: #e97720;
$colorOrange--Light: #f0a46a;
$colorRed--Base: #e22828;
$colorRed--Light: #e85353;
$colorRed--Lighter: #f19494;
$colorRed--Lightest: #fceaea;
$colorYellow--Base: #f5c92b;
$colorYellow--Dark: #dbb11f;
$colorYellow--Light: #f9d86b;

// COLORS
// ----------------------------------

// Violet
$colorDarkestViolet: #251877;
$colorDarkViolet: #2f1f98;
$colorMediumViolet: #3c28c1;
$colorViolet: #614de6;
$colorLavender: #8274e2;
$colorLighterLavender: #aca2ec;
$colorLightestLavender: #efedfe;

// Orange
$colorOrange: #ec8a40;
$colorOrangeLight: #f6e7db;

// Greys
$colorWhite: #ffffff;
$colorGrayCool: #f2f5fa;
$colorGrayCoolLighter: #f7fafe;
$colorGrayCoolLightest: #fbfcfe;
$colorGrayWarm: #eaeef5;
$colorGrayMedium: #dae1ed;

// Font colors
$colorNavyDark: #1b1543;
$colorCharcoalLight: #b2b0c1;
$colorCharcoal: #85829a;
$colorBlackWarm: #141414;

// Yellow
$colorYellow: #ffc400;
$colorYellowLight: #fff5d3;

// Green
$colorGreen: #3b873e;
$colorGreenLight: #6ca83e;
$colorGreenLightest: #e8f5e9;

// Red
$colorRed: #e31b0c;
$colorRedLight: #feebee;
$colorRedDark: #b52020;
$colorRedDarkest: #8e0505;

// Blue
$colorBlue: #0b79d0;
$colorBlueLight: #e3f2fd;
