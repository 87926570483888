
          @import "./src/styles/_index.scss";
        
$checkboxSize: 2.4rem;

.root {
  margin: spacing(7) 0;
  flex-basis: 100%;
  display: flex;
  justify-content: left;
  position: relative;

  &:hover {
    .checkbox {
      border-width: 0.2rem;
    }
  }
}

.contentWrapper {
  padding-top: 0.3rem;
}

.label {
  display: block;
  @include typoBodyCopyStandard;
  cursor: pointer;
  position: relative;
  color: $colorGray--Darkest;
  transform: translateY(-0.1rem);
  padding-left: spacing(2);

  a {
    color: $colorOrange;
    cursor: pointer;
  }
}

.errorMessage {
  display: block;
  margin-top: 0.8rem;
  padding-left: spacing(2);
}

.checkbox {
  @include flexCenter;
  flex-shrink: 0;
  width: $checkboxSize;
  height: $checkboxSize;
  border: 0.1rem solid $colorCharcoal;
  border-radius: $defaultRadius;
}

.checkbox:before {
  @include defaultTransition;
  display: block;
  content: '';
  height: 0;
  width: 0;
  border-radius: calc($defaultRadius - 0.2rem);
  transition: all 200ms ease-in-out;
  opacity: 0;
}

.input {
  cursor: pointer;
  width: $checkboxSize;
  height: $checkboxSize;
  position: absolute;
  opacity: 0;
  z-index: 1;

  &:focus + .checkbox {
    border: 0.2rem solid $colorBlue--Base;
  }

  &:checked + .checkbox:before {
    background-color: $colorBlue--Base;
    opacity: 1;
    height: 1.6rem;
    width: 1.6rem;
  }

  &:disabled + .checkbox {
    border-color: $colorGrayMedium;

    &:before {
      background-color: $colorGrayMedium;
    }

    & + .contentWrapper .label {
      color: $colorGrayMedium;
    }
  }

  &.hasError + .checkbox {
    border-width: 0.2rem;
    border-color: $colorOrange;
  }
}
