
          @import "./src/styles/_index.scss";
        
.root {
  position: fixed;
  z-index: $zindexMobileMenu;
  top: 0;

  @media #{$desktop-only} {
    display: none;
  }
}

.menuContainer {
  position: relative;
  z-index: $zindexMobileMenu;
  width: 22rem;
  height: 100vh;
  background-color: $colorWhite;

  &--enter {
    transform: translateX(-28rem);
  }
  &--enterActive {
    transform: translateX(0);
    @include defaultTransition;
  }
  &--exit {
    transform: translateX(0);
  }
  &--exitActive {
    transform: translateX(-28rem);
    @include defaultTransition;
  }
}

.logoWrapper {
  padding: spacing(5, 4);
}
