
          @import "./src/styles/_index.scss";
        
.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: $colorWhite;
}
