
          @import "./src/styles/_index.scss";
        
.outerContainer {
  background-color: $colorGrayCool;
  width: 51rem;
  padding: 1.5rem;
  margin: auto;
  border-radius: $defaultRadius;
}

.innerContainer {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  border: 0.1rem solid $colorGrayMedium;
  border-radius: $defaultRadius;
  position: relative;
  flex-flow: column;
  display: flex;
}
