
          @import "./src/styles/_index.scss";
        
@import 'colors';

// CSS vars export for _colors.ts.
:export {
  colorDarkestViolet: $colorDarkestViolet;
  colorDarkViolet: $colorDarkViolet;
  colorMediumViolet: $colorMediumViolet;
  colorViolet: $colorViolet;
  colorLavender: $colorLavender;
  colorLighterLavender: $colorLighterLavender;
  colorLightestLavender: $colorLightestLavender;
  colorOrange: $colorOrange;
  colorOrangeLight: $colorOrangeLight;
  colorWhite: $colorWhite;
  colorGrayCool: $colorGrayCool;
  colorGrayCoolLighter: $colorGrayCoolLighter;
  colorGrayCoolLightest: $colorGrayCoolLightest;
  colorGrayWarm: $colorGrayWarm;
  colorGrayMedium: $colorGrayMedium;
  colorNavyDark: $colorNavyDark;
  colorCharcoal: $colorCharcoal;
  colorBlackWarm: $colorBlackWarm;
  colorYellow: $colorYellow;
  colorYellowLight: $colorYellowLight;
  colorGreen: $colorGreen;
  colorGreenLight: $colorGreenLight;
  colorGreenLightest: $colorGreenLightest;
  colorRed: $colorRed;
  colorRedDark: $colorRedDark;
  colorRedDarkest: $colorRedDarkest;
  colorRedLight: $colorRedLight;
  colorBlue: $colorBlue;
  colorBlueLight: $colorBlueLight;
}
