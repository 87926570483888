
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
  z-index: calc($zindexTopbar - 1);
  border-bottom: 0.1rem solid $colorGrayCool;
}

.navigationBar {
  @include container;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(3, 10);

  @media #{$tablet-down} {
    padding: spacing(3, 4);
  }
}

.organizationMenu {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  > * {
    margin-right: spacing(3);
  }

  @media #{$tablet-down} {
    display: none;
  }
}

.organizationDropdown {
  > button span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15rem;
  }

  @media #{$phone-only} {
    margin-right: spacing(2);
    > button span {
      max-width: 13rem;
    }
  }

  @media #{$desktop-only} {
    display: none;
  }
}
