
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  @include shadowModalBox;
  background-color: $colorWhite;
}

.header {
  flex-grow: 0;
  flex-shrink: 0;
}

.contentWrapper {
  max-height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &.disableScrollOnLargeScreen {
    @media #{$tablet-up} {
      overflow-y: visible;
    }
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: spacing(6);
    border-radius: $defaultRadius;
    background-image: linear-gradient(rgba($colorWhite, 0), rgba($colorWhite, 1));
  }
}

.type-- {
  &default {
    max-width: 72rem;
    max-height: calc(100vh - 6rem);
    border-radius: $defaultRadius;

    .contentWrapper::after {
      border-radius: $defaultRadius;
    }
  }

  &slideout {
    max-width: 70rem;
    height: calc(100vh - 2rem);
    border-radius: $radius16;
    margin: spacing(2);

    .contentWrapper::after {
      border-radius: $radius16;
    }
  }
}
