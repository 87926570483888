
          @import "./src/styles/_index.scss";
        
.root {
  margin-left: auto;
  margin-right: auto;
  margin-top: 16rem;
  text-align: center;
  max-width: 62rem;
}
