@keyframes #{$rt-namespace}__flipIn {
  from {
    transform: perspective(40rem) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(40rem) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(40rem) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(40rem) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(40rem);
  }
}

@keyframes #{$rt-namespace}__flipOut {
  from {
    transform: perspective(40rem);
  }
  30% {
    transform: perspective(40rem) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(40rem) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.#{$rt-namespace}__flip-enter {
  animation-name: #{$rt-namespace}__flipIn;
}

.#{$rt-namespace}__flip-exit {
  animation-name: #{$rt-namespace}__flipOut;
}
