
          @import "./src/styles/_index.scss";
        
.variant--inInput {
  position: absolute;
  right: 1rem;
  top: 0.9rem;
}

.circle {
  border-radius: 50%;
  border: 0;
}

.noHover {
  pointer-events: none;
}
