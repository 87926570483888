
          @import "./src/styles/_index.scss";
        
.noTopMargin {
  margin-top: 0;
}

.bottomButtons {
  justify-content: space-between !important;
  button {
    margin: 0 !important;
  }
}
