
          @import "./src/styles/_index.scss";
        
.footer {
  width: 100%;
  bottom: 0;

  .buttons {
      display: flex;
      gap: 1rem;

      a {
        flex: 1 1 auto;
      }
  }
}
