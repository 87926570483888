
          @import "./src/styles/_index.scss";
        
.wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: $zindexBackdrop;
}

.root,
.childrenWrapper {
  @include flexCenter;
  position: fixed;
  inset: 0;
}

.childrenWrapper {
  z-index: $zindexBackdrop + 1;
}

.root {
  padding: spacing(5, 3);
  background-color: rgba($colorBlackWarm, 0.5);
  z-index: $zindexBackdrop;

  &--enter {
    opacity: 0;
  }
  &--enterActive {
    transition: $animationDefaultTransition;
    opacity: 1;
  }
  &--exit {
    opacity: 1;
  }
  &--exitActive {
    transition: $animationDefaultTransition;
    opacity: 0;
  }
}
