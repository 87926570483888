.#{$rt-namespace}__toast {
  &-theme--dark {
    background: var(--toastify-color-dark);
    color: var(--toastify-text-color-dark);
  }
  &-theme--light {
    color: var(--toastify-text-color-light);
  }
  &-theme--light#{&}--default {
    background: var(--toastify-color-light);
  }
  &-theme--light#{&}--info {
    background: $colorJetStream--Lighter;
    border-color: $colorViolet;
    .#{$rt-namespace}__toast-icon {
      color: $colorViolet;
    }
  }
  &-theme--light#{&}--success {
    background: $colorGreen--Lightest;
    border-color: $colorGreenLight;
    .#{$rt-namespace}__toast-icon {
      color: $colorGreenLight;
    }
  }
  &-theme--light#{&}--warning {
    background: $colorYellowLight;
    border-color: $colorYellow--Base;
    .#{$rt-namespace}__toast-icon {
      color: $colorYellow--Base;
    }
  }
  &-theme--light#{&}--error {
    background: $colorRed--Lightest;
    border-color: $colorRed--Base;
    .#{$rt-namespace}__toast-icon {
      color: $colorRed--Base;
    }
  }
  &-theme--colored#{&}--default {
    background: var(--toastify-color-light);
    color: var(--toastify-text-color-light);
  }
  &-theme--colored#{&}--info {
    color: var(--toastify-text-color-info);
    background: var(--toastify-color-info);
  }
  &-theme--colored#{&}--success {
    color: var(--toastify-text-color-success);
    background: var(--toastify-color-success);
  }
  &-theme--colored#{&}--warning {
    color: var(--toastify-text-color-warning);
    background: var(--toastify-color-warning);
  }
  &-theme--colored#{&}--error {
    color: var(--toastify-text-color-error);
    background: var(--toastify-color-error);
  }
}

.#{$rt-namespace}__progress-bar {
  &-theme--light {
    background: var(--toastify-color-progress-light);
  }
  &-theme--dark {
    background: var(--toastify-color-progress-dark);
  }
  &--info {
    background: var(--toastify-color-progress-info);
  }
  &--success {
    background: var(--toastify-color-progress-success);
  }
  &--warning {
    background: var(--toastify-color-progress-warning);
  }
  &--error {
    background: var(--toastify-color-progress-error);
  }
  &-theme--colored#{&}--info,
  &-theme--colored#{&}--success,
  &-theme--colored#{&}--warning,
  &-theme--colored#{&}--error {
    background: var(--toastify-color-transparent);
  }
}
