
          @import "./src/styles/_index.scss";
        
.root {
  padding: 0.5rem;
  position: absolute;
  z-index: 9999;
  cursor: default;
  animation: 0.5s fadeIn;
  max-width: 40rem;

  [class*='__content'] {
    @include typoBodyCopySmall;
    padding: 1rem;
    border-radius: $defaultRadius;
    background: $colorGray--Darkest;
    color: $colorWhite;
    text-align: center;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: $defaultRadius solid transparent;
  }

  &[class*='--top']:after {
    top: auto;
    border-bottom: none;
    border-top-color: $colorGray--Darkest;
  }

  &[class*='--left']:after {
    left: auto;
    border-right: none;
    border-left-color: $colorGray--Darkest;
  }

  &[class*='--right']:after {
    right: auto;
    border-left: none;
    border-right-color: $colorGray--Darkest;
  }

  &[class*='--bottom']:after {
    bottom: auto;
    border-top: none;
    border-bottom-color: $colorGray--Darkest;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
