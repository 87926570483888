
          @import "./src/styles/_index.scss";
        
.root {
  @include contentWrapper;
}

.header {
  margin-top: spacing(12);
  margin-bottom: spacing(6);
}

.organizationButton {
  margin-bottom: spacing(2);
}

.buttonsContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
}
