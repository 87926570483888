
          @import "./src/styles/_index.scss";
        
.currency {
  display: flex;
  span {
    margin-left: 0.3rem;
  }
}

.bottomButtons {
  justify-content: space-between !important;
  button {
    margin: 0 !important;
  }
}
