
          @import "./src/styles/_index.scss";
        
@import 'layout';

// CSS vars export for _layout.ts.
:export {
  spacingBase: $spacingBase;
  zindexDropdownMenu: $zindexDropdownMenu;
  zindexFormOverlayElement: $zindexFormOverlayElement;
}
