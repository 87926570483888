
          @import "./src/styles/_index.scss";
        
.label {
  min-width: 20rem;
}

.value {
  overflow: hidden;
  overflow-wrap: anywhere;
}
