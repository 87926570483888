
          @import "./src/styles/_index.scss";
        
.listContainer {
  background-color: $colorGrayCoolLightest;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.resultsWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.paginationBar {
  margin-top: spacing(20);
}
