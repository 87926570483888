
          @import "./src/styles/_index.scss";
        
.button {
  @include defaultTransition;
  @include flexCenter;
  @include fontMainFamily;
  position: relative;
  border: 0.1rem solid transparent;
  border-radius: $radiusInfinite;
  font-size: 1.6rem;
  outline: none;
  font-weight: $fontWeightBold;

  &:hover {
    cursor: pointer;
  }
}

.iconContainer {
  display: inline-flex;
  align-items: center;

  &.iconPosition--leading {
    &.isNextToContent {
      padding-right: spacing(2.5);
    }
  }

  &.iconPosition--trailing {
    &.isNextToContent {
      padding-left: spacing(2.5);
    }
  }
}

.align-- {
  &center {
    margin-left: auto;
    margin-right: auto;
  }

  &right {
    margin-left: auto;
  }
}

.size-- {
  &normal {
    padding: 1.4rem 2rem;

    &.hasIconOnly {
      padding: 1.5rem;
    }

    @media #{$phone-only} {
      padding: 1.1rem 1.8rem;
    }
  }

  &small {
    padding: 0.9rem 1.6rem;
    font-size: 1.4rem;

    &.hasIconOnly {
      padding: 1.1rem;
    }
  }

  &extraSmall {
    padding: 0.65rem 0.85rem;
    font-size: 1.1rem;

    &.hasIconOnly {
      padding: 1rem;
    }
  }
}

.variant-- {
  &primary {
    color: $colorWhite;
    background-color: $colorViolet;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorMediumViolet;
      }
      &:active,
      &.isActive {
        background-color: $colorDarkViolet;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        background-color: $colorGrayCool;
        font-weight: $fontWeightNormal;
      }
    }

    &.isLoading {
      background-color: $colorGrayCool;
    }
  }

  &secondary {
    color: $colorMediumViolet;
    border: 0.1rem solid $colorMediumViolet;
    background-color: $colorWhite;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active,
      &.isActive {
        background-color: $colorLightestLavender;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        border-color: $colorGrayWarm;
      }
    }

    &.isLoading {
      border-color: $colorGrayCool;
      background-color: $colorGrayCool;
    }
  }

  &tertiary {
    color: $colorNavyDark;
    border: 0.1rem solid $colorGrayMedium;
    background-color: $colorWhite;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorGrayCool;
      }
      &:active {
        background-color: $colorGrayWarm;
      }
      &.isActive {
        .leadingIcon {
          color: $colorViolet;
        }
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        border-color: $colorGrayWarm;
      }
    }

    &.isLoading {
      border-color: $colorGrayCool;
      background-color: $colorGrayCool;
    }
  }

  &textCta {
    color: $colorNavyDark;
    background-color: transparent;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorGrayCool;
      }
      &:active,
      &.isActive {
        background-color: $colorGrayWarm;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
      }
    }
  }

  &hyperlink {
    padding: 0;
    color: $colorMediumViolet;
    background-color: transparent;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
      &:active,
      &.isActive {
        color: $colorDarkViolet;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
      }
    }
  }

  &hyperlinkSecondary {
    padding: 0;
    color: $colorBlackWarm;
    background-color: transparent;
    text-decoration: underline;

    &:not(.isLoading) {
      &:active,
      &.isActive {
        text-decoration: none;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
      }
    }
  }

  &dropdownOpaque {
    padding: 0.55rem 1.2rem;
    color: $colorGray--Darkest;
    background-color: $colorGrayCool;
    font-size: 1.4rem;
    border-radius: 999rem;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active,
      &.isActive {
        background-color: $colorGrayMedium;
      }
      &.isDisabled {
        color: $colorCharcoal;
        background-color: $colorGrayCool;
      }
    }
  }

  &dropdownTransparent {
    padding: 0.55rem 0;
    color: $colorGray--Darker;
    font-size: 1.4rem;
    background-color: transparent;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active,
      &.isActive {
        color: $colorGray--Darkest;
      }
      &.isDisabled {
        color: $colorGray--Light;
      }
    }
  }

  &destructive {
    color: $colorWhite;
    background-color: $colorRed;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorRedDark;
      }
      &:active,
      &.isActive {
        background-color: $colorRedDarkest;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        background-color: $colorGrayCool;
      }
    }

    &.isLoading {
      background-color: $colorGrayCool;
    }
  }

  &destructiveSecondary {
    color: $colorRed;
    border-color: $colorRed;
    background-color: $colorWhite;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active,
      &.isActive {
        background-color: $colorRedLight;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        border-color: $colorGrayWarm;
      }
    }

    &.isLoading {
      border-color: $colorGrayCool;
      background-color: $colorGrayCool;
    }
  }

  &navigation {
    padding: 0.35rem 1.2rem;
    color: $colorGray--Darkest;
    background-color: transparent;
    font-size: 1.4rem;
    border-radius: 999rem;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorGrayCool;
      }
      &:active,
      &.isActive {
        color: $colorWhite;
        background-color: $colorNavyDark;
      }
      &.isDisabled {
        color: $colorGray--Light;
        background-color: transparent;
      }
    }
  }

  &pagination {
    color: $colorGray--Darkest;
    border: 0.1rem solid $colorGrayWarm;
    background-color: $colorWhite;
    padding: spacing(2, 3);
    font-weight: $fontWeightNormal;
    border-radius: $defaultRadius;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active {
        border-color: $colorCharcoal;
      }
      &.isActive {
        border-color: $colorViolet;
        background-color: $colorViolet;
        color: $colorWhite;
        font-weight: $fontWeightBold;
      }
      &.isDisabled {
        opacity: 0.3;
      }
    }
  }

  &icon {
    padding: 0 !important;
    color: $colorGray--Darker;
    background-color: transparent;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active,
      &.isActive {
        color: $colorGray--Darkest;
      }
      &.isDisabled {
        color: $colorGray--Light;
      }
    }
  }
}

.isDisabled,
.isDisabled:hover {
  pointer-events: none;
}

.isLoading {
  cursor: auto !important;
  color: $colorCharcoal;

  &:not(.hasLeadingIcon) {
    .iconContainer,
    .label {
      visibility: hidden;
    }

    .spinner {
      position: absolute;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }
  }

  &.hasLeadingIcon {
    .spinner {
      margin-right: spacing(2.5);
    }
  }
}
