
          @import "./src/styles/_index.scss";
        
.root {
  text-align: center;
  max-width: 59.5rem;
  margin: spacing(16) auto;
}

.icon {
  color: $colorGreenLight;
}
