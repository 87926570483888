
          @import "./src/styles/_index.scss";
        
.root {
  @include defaultTransition;
  @include fontMainFamily;
  font-weight: $fontWeightBold;
  font-size: 1.4rem;
  width: 100%;
  border: none;
  outline: none;
  color: $colorGray--Darkest;
  background-color: $colorWhite;
  display: flex;

  &:hover:not(.isDisabled),
  &:focus {
    cursor: pointer;
    background-color: $colorGrayCool;
  }
  &:active {
    background-color: $colorGrayMedium;
  }
}

// This wrapper is here to make sure the faded effect, when an item
// doesn't affect its background color.
.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.isDisabled .contentWrapper {
  opacity: 0.5;
}

.iconContainer {
  display: inline-flex;
  align-items: center;
  margin-right: spacing(2);
}

.label {
  line-height: 2.2rem;
  text-align: left;
}

.variant-- {
  &mobileMenu {
    padding: 1.2rem 1.6rem;

    &.isActive {
      color: $colorWhite;
      background-color: $colorNavyDark;
    }
  }
  &dropdown {
    padding: 0.8rem 1.6rem;
  }
  &dropdownCta {
    padding: 0.8rem 1.6rem;
    color: $colorOrange;
  }
}
