
          @import "./src/styles/_index.scss";
        
.footer {
  width: 100%;
  bottom: 0;

  .buttons {
    display: flex;
    gap: 1rem;

    button {
      width: 100%;
    }
  }
}

.list {
  span {
    overflow-wrap: anywhere;
  }
}
