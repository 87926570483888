
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
  border-bottom: 0.1rem solid $colorGrayCool;
  z-index: $zindexTopbar;
}

.topBar {
  @include container;
  display: flex;
  align-items: center;
  padding: spacing(5, 10);

  @media #{$tablet-down} {
    padding: spacing(5, 4);
  }
}

.headerLogo {
  display: block;
  line-height: 0;

  .logoFull {
    @media #{$phone-only} {
      display: none;
    }
  }
  .logoSign {
    @media #{$tablet-up} {
      display: none;
    }
  }
}

.mobileMenuButton {
  margin-right: spacing(2);
  padding: spacing(2) !important;
  @media #{$desktop-only} {
    display: none;
  }
}

.navItems {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.organizationDropdown {
  margin-right: spacing(10);

  > button span {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 20rem;
    overflow: hidden;
  }

  @media #{$tablet-down} {
    display: none;
  }
}

.userProfileDropdown {
  margin-left: spacing(5);

  @media #{$phone-only} {
    > button span {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 12rem;
      overflow: hidden;
    }
  }
}
